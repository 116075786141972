import { ref, reactive, computed } from "vue";
export default {
  props: {
    value: [String, Number]
  },
  setup: function setup(props) {
    var refProgressBarText = ref(null);
    var cData = reactive({
      progressBarTextWidth: computed(function () {
        var result = 0;

        if (refProgressBarText.value) {
          result = refProgressBarText.value.clientWidth;
        }

        return result;
      }),
      thisValue: computed(function () {
        var value = props.value ? parseFloat(props.value) : 0;
        return value > 100 ? 100 : value;
      }),
      leftPcrtString: computed(function () {
        if (cData.thisValue < 19) {
          var base = 17 + 8 + cData.progressBarTextWidth;
          return "".concat(base, "px");
        } else {
          return "".concat(cData.thisValue, "%");
        }
      })
    });
    return {
      cData: cData,
      refProgressBarText: refProgressBarText
    };
  }
};