import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_link = _resolveComponent("f7-link");

  var _component_f7_toolbar = _resolveComponent("f7-toolbar");

  return _openBlock(), _createBlock(_component_f7_toolbar, {
    class: "base-toolbar",
    bottom: "",
    "no-shadow": "",
    "no-hairline": ""
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_f7_link, {
        text: $setup.t('activity'),
        "icon-f7": "tickets",
        href: "/activity_list"
      }, null, 8, ["text"]), _createVNode(_component_f7_link, {
        text: $setup.t('album'),
        "icon-f7": "photo_on_rectangle",
        href: "/album_list"
      }, null, 8, ["text"]), _createVNode(_component_f7_link, {
        text: $setup.t('home'),
        "icon-f7": "house_alt_fill",
        class: "link-home",
        href: "/home"
      }, null, 8, ["text"]), _createVNode(_component_f7_link, {
        text: $setup.t('product'),
        "icon-f7": "gift_alt",
        href: "/product_list"
      }, null, 8, ["text"]), _createVNode(_component_f7_link, {
        text: $setup.t('member'),
        "icon-f7": "person_2_square_stack",
        href: "/member_center"
      }, null, 8, ["text"])];
    }),
    _: 1
  });
}