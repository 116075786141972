export default{
    dialog: {
        buttonOk: 'OK',
        buttonCancel: 'Cancel',
    },
    photoBrowser:{
        bannerPhotoBrowser:'Close'
    },
    newFabrics: "NEW FABRICS",
    news: 'NEWS',
    chatRoom: 'Message Board',
    archive: 'Archive',
    labdipStatus: 'Labdip Status',
    fabricname: 'Fabric name',
    'fabric#': 'Fabric #',
    color: 'Color',
    receivedDate: 'Received date',
    leadTime: 'Lead time',
    confirmDate: 'Confirm date',
    programSchedule: 'PROGRAM SCHEDULLE',
    rdStatus: 'R&D STATUS',
    localeSwitch: 'Language switch',
    changePwd: 'Change Password',
    logout: 'Logout',
    signUp: 'SIGN UP',
    notification: 'NOTIFICATION',
    orderStatus: "Order Status",

    logoutSuccess: "Log out",
    addToCartSuccess: "Add to cart",
    removeFromCartSuccess: "Remove from cart",

    youSureToDeleteAllMail: "Confirm to delete all mails?",
    youSureToDeleteSelectedMail: "Confirm to delete selected mails?",
    youSureToMarkeReadAllMail: "Confirm to read all mails?",

    keyword: 'keyword',

    alertMsg:{
        error: 'Error',
        serverError: 'Server error, please contact maintenance person.',
        connectionError: 'The Internet connection is unstable. Please try again later.'
    }
}