export default {
  props: {
    popupType: {
      type: String,
      default: ""
    },
    popupOpened: {
      type: Boolean,
      default: false
    }
  }
};