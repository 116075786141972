// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 , {Dom7} from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents, f7, f7ready } from 'framework7-vue/bundle';

import store from '@/store/store.js';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Stylesnpm
import '@/css/icons.css';
import '@/css/app.scss';

import { upperFirst, camelCase } from "lodash";

// Import App Component
import App from "@/App.vue";

// 表單驗證
import "@/js/validations.js";

// 圖形驗證
import BaseIdentify from "@/components/BaseIdentify";

// i18n
import { i18n } from "@/js/i18n.js";

// base JS
import { MukiStorage } from "@/js/base.js";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// App 常數
const mukiConst ={
    // 語系
    locale:{
        TW: 'tw',
        EN: 'en'
    },
    // 會員身份
    memberType:{
        // 一般
        GENERAL: 0,
        // 業務
        SALES: 1,
    },
    // 性別
    gender: {
        FEMALE: 0,
        MALE: 1,
        EMPTY: 2
    },
    // 付款狀態
    orderStatus: {

    },
    // 洽詢車
    inquiryCart:{
        // 已加入洽詢車
        IN_CART: 1,
        // 未加入洽詢車
        NOT_IN_CART: 0,
    },
    // 發票
    invoiceType: {
        // 2聯
        DUPLICATE_UNIFORM_INVOICE: 1,
        // 3聯
        TRIPLICATE_UNIFORM_INVOICE: 2,
        // 捐贈
        DONATE: 3,
    },
    // 付款方式
    paymentMethod: {
        // 刷卡
        CARD: 0,
        // LINE PAY
        LINE_PAY: 1,
        // 匯款
        REMIT: 2,
        // 點數
        POINT: 3,
    },
    chatRoomAddType: {
        // new fabrics 進來
        FROM_NEW_FABRICS: 0,
        // 最新消息詳細頁進來
        FROM_NEWS_DETAILED: 1,
        // 聊天室新增按鈕進來的
        FROM_CLICK_CHAT_ROOM_ADD: 2,
    },
    // 驗證
    csrToken:{
        INVALID: -99,
    },
    token:{
        INVALID: -98,
    },
}
window.mukiConst = mukiConst

// App 設定相關資料
const AppSetting = {
    get setting(){
        var appSetting = MukiStorage.ls.get('appSetting') || {}
        return appSetting
    },
    update(newSetting){
        var newSetting = newSetting || {};
        var nowSetting = this.setting;

        if ( !newSetting.constructor === Object ) throw '設定值請傳入物件!'

        MukiStorage.ls.set('appSetting', $.extend(true, {}, nowSetting, newSetting))
    },
    // 提示
    driverHint:{
        // 是否要顯示 Driver hint
        canShowDriverHint(pageName){
            var showupIntervalByDays = 90;
            // 快速測試用
            var showupIntervalByMinutes = 1;
            var setting = AppSetting.setting || {};
            var time = (setting && setting.driverHint && setting.driverHint && setting.driverHint[pageName] && setting.driverHint[pageName].lastShowupTime) || null;
            var today = (new Date()).getTime();

            if (!time) return true;

            const diffTime = Math.abs(parseInt(time) - today);
            var diffDays = diffTime / (1000 * 60 * 60 * 24);
            // 快速測試用
            var diffMinutes = diffTime / (1000 * 60)

            return diffDays >= showupIntervalByDays
        },
        // 更新本次 Driver Hint Time
        updateShowDriverHintTime(pageName){
            var setting = AppSetting.setting || {};
            
            var newSetting = {
                driverHint:{
                }
            }

            newSetting.driverHint[pageName] = {
                lastShowupTime: (new Date()).getTime()
            }

            AppSetting.update(newSetting)
        }
    }
}
window.AppSetting = AppSetting;

// Init App
const app = createApp(App);
window.app = app;

// store
app.use(store)
window.store = store;


// 定義全域可取用的components
function importAll(requireComponent) {
    requireComponent.keys().forEach((fileName) => {
        const componentConfig = requireComponent(fileName);

        const componentName = upperFirst(
            camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
        );

        app.component(
            componentName,
            componentConfig.default || componentConfig
        );
    });
}

importAll(require.context("@/components", false, /Base[A-Z]\w+\.(vue|js)$/));

importAll(
    require.context("@/components/form", false, /Base[A-Z]\w+\.(vue|js)$/)
);

// Register Framework7 Vue components
registerComponents(app);

// F7 可使用時
f7ready(() => {
    // Framework7's initialized instance
    window.f7 = f7;
    window.$$ = Dom7;

    // 改寫f7 alert - 讓語系動態切換
    f7.dialog.alert = function(text, title, callback){
        var dialog = f7.dialog.create({
            text: text,
            title: title,
            buttons: [
                {
                    text: i18n.global.t('dialog.buttonOk'),
                    onClick: function(){
                        if ( 'function' == typeof callback ) {
                            callback()
                        }
                    }
                }
            ]
        })

        dialog.open()
    }

    // 改寫f7 confirm - 讓語系動態切換
    f7.dialog.confirm = function(text, title, callbackOk, callbackCancel){
        var dialog = f7.dialog.create({
            text: text,
            title: title,
            buttons: [
                {
                    text: i18n.global.t('dialog.buttonCancel'),
                    onClick: function(){
                        if ( 'function' == typeof callbackCancel ) {
                            callbackCancel()
                        }
                    }
                },
                {
                    text: i18n.global.t('dialog.buttonOk'),
                    onClick: function(){
                        if ( 'function' == typeof callbackOk ) {
                            callbackOk()
                        }
                    }
                },
            ]
        })

        dialog.open()
    }
})

app.use(BaseIdentify);
app.use(i18n);

// Mount the app
app.mount('#app');