export default {
  props: {
    checkboxShow: {
      type: Boolean,
      default: false
    },
    isRead: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    noDate: {
      type: Boolean,
      default: false
    },
    noText: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    date: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    href: {
      type: String
    }
  }
};