import { ref, watch } from 'vue';
export default {
  components: {},
  props: {
    blockCenter: Boolean,
    // 字定義顯示
    customShow: Boolean,
    show: Boolean
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var refRoot = ref(null);
    var customShow = {
      timeout: '',
      show: function show() {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          $(refRoot.value).show();
        }, 400
        /* 當需要spinner的動做超過一小段ms還沒被完成，就顯示spinner */
        );
      },
      hide: function hide() {
        if (this.timeout) clearTimeout(this.timeout);
        $(refRoot.value).hide();
      }
    };

    if (props.customShow) {
      watch(function () {
        return props.show;
      }, function () {
        if (props.show) {
          customShow.show();
        } else {
          customShow.hide();
        }
      });
    }

    return {
      refRoot: refRoot
    };
  }
};