export default {
  props: {
    header: {
      type: Boolean,
      default: false
    },
    content: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    }
  }
};