import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { Counter } from '@/js/functions.js';
import { basePost, postLogin } from "@/js/services/baseService";
export default {
  omponents: {},
  props: {
    f7router: Object
  },
  setup: function setup(props) {
    // 多國語系
    var _useI18n = useI18n({
      messages: {
        "tw": {
          emailAddress: '信箱',
          password: '密碼',
          forgotPassword: '忘記密碼',
          createAnAccount: '建立新帳號',
          signIn: '登入'
        },
        en: {
          emailAddress: 'Email address',
          password: 'Password',
          forgotPassword: 'Forgot password',
          createAnAccount: 'Create an account',
          signIn: 'Login'
        }
      }
    }),
        t = _useI18n.t,
        locale = _useI18n.locale;

    var f7router = props.f7router;
    var loginBtnDisable = ref(false); // form驗證設定

    var _useForm = useForm({
      validateOnMount: false,
      validationSchema: {
        email: {
          required: true,
          email: true
        },
        password: "required"
      },
      initialValues: {}
    }),
        errors = _useForm.errors,
        isSubmitting = _useForm.isSubmitting,
        submitCount = _useForm.submitCount,
        meta = _useForm.meta,
        values = _useForm.values,
        setFieldError = _useForm.setFieldError,
        setErrors = _useForm.setErrors,
        setFieldValue = _useForm.setFieldValue,
        setValues = _useForm.setValues,
        validate = _useForm.validate,
        validateField = _useForm.validateField,
        handleSubmit = _useForm.handleSubmit,
        submitForm = _useForm.submitForm,
        handleReset = _useForm.handleReset,
        resetForm = _useForm.resetForm; // 驗證帳號


    var onFormSubmit = handleSubmit(function (values, actions) {
      // return false;
      var email = values.email,
          password = values.password;
      var postData = {
        email: email,
        password: password
      };
      loginBtnDisable.value = true;
      postLogin(postData).then(function (res) {
        loginBtnDisable.value = false;
        var data = res.data,
            status = res.status;

        if (status === 200 && data["res_code"] === 1) {
          f7.dialog.alert(data["res_content"], "", function () {
            _direct_url('/');
          });
        }
      });
    });
    return {
      t: t,
      values: values,
      onFormSubmit: onFormSubmit,
      loginBtnDisable: loginBtnDisable
    };
  }
};