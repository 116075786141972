import { ref, reactive, computed, watch, nextTick } from 'vue';
import { useField } from "vee-validate";
export default {
  components: {},
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      default: ""
    },
    labelName: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    sty: {
      type: String,
      default: "underline"
    },
    type: {
      type: String,
      default: ""
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    star: {
      // 必填星星
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    inputId: String,
    inputTag: {
      default: 'input'
    },
    inputClass: {
      default: ""
    },
    itemInputWrapOuterClass: {
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    f7: {
      type: String,
      default: ""
    },
    // textarea
    rows: {
      type: Number
    },
    cols: {
      type: Number
    },
    // textarea /
    material: {
      type: String,
      default: ""
    },
    ios: {
      type: String,
      default: ""
    },
    md: {
      type: String,
      default: ""
    },
    aurora: {
      type: String,
      default: ""
    },
    // validate
    errMsg: {
      type: [String, Boolean],
      default: ""
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit,
        slots = _ref.slots;
    var slotsDefault = slots.default,
        end = slots['label-end'];
    var sty = props.sty,
        name = props.name,
        type = props.type;
    var refEl = ref(null);
    var refInput = ref(null);
    var thisType = ref(type); // validate

    var _useField = useField(name),
        thisErrMsg = _useField.errorMessage,
        thisName = _useField.name,
        value = _useField.value,
        meta = _useField.meta,
        errors = _useField.errors,
        resetField = _useField.resetField,
        validate = _useField.validate,
        handleChange = _useField.handleChange,
        handleBlur = _useField.handleBlur,
        setValidationState = _useField.setValidationState,
        checked = _useField.checked;

    var computeStyArr = computed(function () {
      var newArr = [];
      sty.split(' ').forEach(function (sty, idx) {
        if (sty) {
          newArr.push(sty);
        }
      });
      return newArr;
    });
    var computeStyStr = computed(function () {
      var arr = computeStyArr.value.map(function (sty, idx) {
        return 'sty-' + sty;
      });

      if (thisErrMsg.value) {
        arr.push('sty-error');
      }

      return arr.join(' ');
    });
    var isCustomControls = computed(function () {
      return computeStyArr.value.indexOf('custom-controls') > -1;
    });
    var isSelect = computed(function () {
      return computeStyArr.value.indexOf('select') > -1;
    });
    var isCustom = computed(function () {
      return computeStyArr.value.indexOf('custom') > -1;
    });
    var isPassword = computed(function () {
      return computeStyArr.value.indexOf('password') > -1;
    });
    var isOutline = computed(function () {
      return computeStyArr.value.indexOf('outline') > -1;
    });
    var isFloatPlaceholder = computed(function () {
      return isOutline.value;
    }); // password

    var togglePwd = function togglePwd() {
      if (thisType.value == 'password') {
        thisType.value = 'text';
      } else {
        thisType.value = 'password';
      }
    }; // password /
    // f7 input with value 處裡 :
    // float-placeholder 的樣式


    watch(function () {
      return props.modelValue;
    }, function (now, prev) {
      nextTick(function () {
        if (now !== undefined) {
          if ($(refEl.value).find('.item-input-width-value').length == 0) {
            // 觸發 F7 框架 input with value 檢查
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", false, true);
            refInput.value.dispatchEvent(evt);
          }
        }
      });
    });
    return {
      refEl: refEl,
      refInput: refInput,
      thisType: thisType,
      computeStyArr: computeStyArr,
      computeStyStr: computeStyStr,
      isCustomControls: isCustomControls,
      isSelect: isSelect,
      isCustom: isCustom,
      isPassword: isPassword,
      isOutline: isOutline,
      isFloatPlaceholder: isFloatPlaceholder,
      thisErrMsg: thisErrMsg,
      // password
      togglePwd: togglePwd
    };
  }
};