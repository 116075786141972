import { baseGet, basePost } from "@/js/services/baseService";
import { computed, reactive } from "vue";

const memberDataList = [
    // 身份
    // 0 一般、1 業務
    'memberType',
    // 登入token
    'token',
    'mail',
    'isLogined'
]

export default {
    namespaced: true,

    state: () => ({
        memberType: null,
        token: null,
        mail: null,
        isLogined: false,
    }),
    getters: {
        // 已登入
        isLogined(state, getters){
            return state.isLogined;
        },
        // 詳細
        authInfo(state, getters){
            return reactive({
                memberType: computed(() => parseInt(state.memberType)),
                token: computed(() => state.token),
                mail: computed(() => state.mail),
                isLogined: computed(() => state.isLogined),
            })
        }
    },
    actions: {
        login({commit}, data) {
            commit('updateMemberData', data);
        },
        logout({ commit }, data) {
            var emptyObj = {
            }

            memberDataList.forEach(name => {
                emptyObj[name] = '';
            });

            commit('updateMemberData', emptyObj)
        },
        init({commit}){
            var dataObj = {
            }

            memberDataList.forEach(name => {
                let data = localStorage.getItem(name)
                if ( data ) {
                    try {
                        data = JSON.parse(data);
                        dataObj[name] = data
                    } catch(err){
                        console.log('auth init err', err)
                    }
                }
            });

            commit('updateMemberData', dataObj)
        },
    },
    mutations: {
        updateMemberData(state, data){
            memberDataList.forEach(name => {
                if ( data[name] !== undefined ) {
                    state[name] = data[name];
                    localStorage.setItem(name, JSON.stringify(data[name]) )
                }
                
                if ( data['token'] !== undefined ) {
                    localStorage.setItem("token", JSON.stringify(data['token']) );
                }
            });
        },
    },
}

