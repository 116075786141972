import { ref, onMounted, reactive } from "vue";
import { f7ready } from 'framework7-vue/bundle';
import routes from "@/js/routes.js";
import store from "@/store/store.js";
import { useI18n } from "vue-i18n";
export default {
  setup: function setup() {
    // 除錯用
    if (process.env.NODE_ENV === 'development') {
      window.store = store;
    } // 初始化


    store.dispatch('init');
    store.dispatch('auth/init'); // 手機原生虛擬返回鍵綁定

    function indexBackAction() {
      muki_back_action(2, function () {
        backPage();
      });
    }

    function inpageBackAction() {
      muki_back_action(1, function () {
        backPage();
      });
    }

    onMounted(function () {
      f7ready(function () {
        // Option 1. Using one 'page:init' handler for all pages
        $$(document).on('page:beforein', function (e) {
          if (f7.view.current.router.currentRoute.path == '/') {
            indexBackAction();
          } else {
            inpageBackAction();
          }
        });
      });
    });
    indexBackAction(); // 取得全域 locale 實體

    var _useI18n = useI18n(),
        t = _useI18n.t,
        locale = _useI18n.locale;

    locale.value = store.state.locale || mukiConst.locale['EN']; // Framework7 Parameters

    var f7params = {
      name: "trueway",
      // App name
      theme: "auto",
      // Automatic theme detection
      // App store
      store: store,
      // App routes
      routes: routes,
      navbar: {
        iosCenterTitle: true,
        mdCenterTitle: true,
        auroraCenterTitle: true
      },
      view: {
        // 打開 broweerHistory 在需要back page時可能會有問題
        // 故僅用於開發
        // browserHistory: process.env.NODE_ENV === 'development' ? true:false,
        xhrCache: false
      },
      photoBrowser: {
        theme: 'dark',
        popupCloseLinkText: t('photoBrowser.bannerPhotoBrowser')
      },
      dialog: {
        buttonOk: t('dialog.buttonOk'),
        buttonCancel: t('dialog.buttonCancel')
      }
    }; // 自動計算基礎px

    var computeRatioOfBaseSize = function computeRatioOfBaseSize() {
      var initSize = 14;
      var htmlEl = document.querySelector("html");
      var windowWidth = htmlEl.clientWidth > 768 ? 768 : htmlEl.clientWidth;
      var baseSize = Number(windowWidth) / 375 * initSize;
      if (baseSize > 16) baseSize = 16;
      if (baseSize < 14) baseSize = 14;
      document.documentElement.style.setProperty('--f7-font-size', baseSize + 'px');
      htmlEl.style.fontSize = baseSize + "px";
    }; // 滑動偵測


    var ScrollDetect = function ScrollDetect() {
      function check(page) {
        var page = page && page.el || f7.view.main.router.currentPageEl;
        var navbar = page && page.navbarEl || f7.view.main.router.currentNavbarEl || $(page).find('> .navbar');
        if (!$(page).length || !$(page).length) return;
        var pageContent = page.querySelector('.page-content');

        if (pageContent.scrollTop > 20) {
          $(navbar).addClass('is-scrolling');
        } else {
          $(navbar).removeClass('is-scrolling');
        }
      }

      $$(document).on('page:beforein', function (e, page) {
        check(page);
        $('.page-content').on('scroll', function (e) {
          check();
        });
      });
      $('.page-content').on('scroll', function (e) {
        check();
      });
    }; // segmented tab


    var SegmentedTab = {
      setActive: function setActive(tabLink) {
        // f7 內建 不會移動 tab inner，導致選到的 tab link有可能會部分被擋住，故採用自行移動方式
        var that = this;
        var tabLink = $(tabLink);
        if (!tabLink.length) return;
        var toolbarInner = tabLink.closest('.toolbar-inner');
        var toolbarInnerW = toolbarInner[0].clientWidth;
        var currentScrollLeft = toolbarInner[0].scrollLeft;
        var tabLinkPos = tabLink.position();
        var diff = tabLink[0].offsetLeft + tabLink[0].clientWidth - (currentScrollLeft + toolbarInnerW);
        var target = currentScrollLeft;

        if (tabLinkPos.left < 0) {
          // btn 太左邊
          target = currentScrollLeft + tabLinkPos.left;
        } else if (diff > 0) {
          // btn 太右邊
          target = currentScrollLeft + diff;
        }

        toolbarInner.animate({
          scrollLeft: target
        }, 300);
        that.updateSegmented(tabLink);
      },
      updateSegmented: function updateSegmented(tabLink, animate) {
        // f7 內建 移動 segement 的位置會有誤差，故改採自行修改位置的方式
        var tabLink = $(tabLink);
        if (!tabLink.length) return;
        var toolbarInner = tabLink.closest('.toolbar-inner');
        var tabLinkW = tabLink.outerWidth();
        var segEl = toolbarInner.find('.tab-link-bg-hilighter');

        if (animate === false) {
          segEl.css('transition', 'none');
        }

        segEl.css('width', tabLinkW);
        segEl.css('left', tabLink.position().left + toolbarInner[0].scrollLeft);

        if (animate === false) {
          segEl.css('transition', '');
        }
      },
      init: function init() {
        var that = this;
        $('body').on('click', '.tab-link', function (e) {
          that.setActive(e.currentTarget);
        });
        $$(document).on('page:afterin', function (e, page) {
          var targetTab = $(page.$el).find('.tabbar-scrollable');
          if (!targetTab.length) return;
          var toolbarInner = targetTab.find('.toolbar-inner');
          var tabLinks = toolbarInner.find('.tab-link');
          var isXOverflow = toolbarInner[0].clientWidth < toolbarInner[0].scrollWidth; // 有任何tab-link寬度不夠

          var isAnyTabLinkOverflow = tabLinks.toArray().some(function (item) {
            // hadleTabUI()預設會讓所有tab-link寬度均分 並加上style width
            if (toolbarInner.outerWidth() / tabLinks.length < item.clientWidth) {
              return true;
            }
          });

          if (!isXOverflow && !isAnyTabLinkOverflow) {
            targetTab.addClass('is-tablink-fixed-width');
          }

          that.updateSegmented(targetTab.find('.tab-link-active'), false);
          targetTab.addClass('is-inited');
        });
      }
    };

    var settingPopupClosed = function settingPopupClosed() {}; // 修改密碼


    var changePwd = function changePwd() {
      f7.popup.close('.popup-setting');
      f7.views.main.router.navigate('/change_pwd', {
        props: {
          pageTitle: t('changePwd'),
          email: store.state.auth.mail
        }
      });
    }; // 切換語系


    var switchLanguage = function switchLanguage(e) {
      if (e.target.checked) {
        // 中文
        store.commit('updateLocale', mukiConst.locale.TW);
      } else {
        // 英文
        store.commit('updateLocale', mukiConst.locale.EN);
      }
    }; // 登出


    var logout = function logout(e) {
      store.dispatch("auth/logout");
      e.target.classList.add('disabled');
      f7.dialog.alert(t('logoutSuccess'), "", function () {
        directURL('/');
      });
    };

    onMounted(function () {
      computeRatioOfBaseSize();
      window.addEventListener("resize", computeRatioOfBaseSize);
      ScrollDetect();
      SegmentedTab.init();
    });
    return {
      t: t,
      mukiConst: mukiConst,
      store: store,
      f7params: f7params,
      settingPopupClosed: settingPopupClosed,
      switchLanguage: switchLanguage,
      changePwd: changePwd,
      logout: logout
    };
  }
};