import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from 'vuex';
import { baseGet, basePost } from "@/js/services/baseService";
export default {
  setup: function setup() {
    // 後端取得 mobile info 用
    getMobileInfo();
    var pageData = reactive({
      banners: [// {
        //     banner_id: 2,
        //     pic_src: "assets/uploads/768x590/banner_list_20210826125117583640.jpg",
        //     topic: "test1",
        // }
      ]
    });
    var btnActiveName = ref("");
    var mailUnreadNum = ref(0);
    var chatUnreadNum = ref(0); // store

    var store = useStore(); // auth

    var authInfo = store.getters['auth/authInfo'];

    var openSettingPopup = function openSettingPopup() {
      f7.popup.open('.popup-setting');
    }; // 多國語系


    var _useI18n = useI18n({
      messages: {
        "tw": {
          newFabrics: "新品",
          new: "最新消息",
          chatRoom: "留言板",
          labdipStatus: "Labdip狀態",
          RDStatus: "研發狀態"
        },
        en: {
          newFabrics: "New Fabrics",
          new: "News",
          chatRoom: "Message Board",
          labdipStatus: "Labdip Status",
          RDStatus: "R&D Status"
        }
      }
    }),
        t = _useI18n.t,
        locale = _useI18n.locale; // 首頁 - 輪播


    basePost("/api_banner/banner").then(function (res) {
      var data = res.data,
          status = res.status;
      var resData = data["res_data"];

      if (status === 200 && data["res_code"] === 1) {
        pageData.banners = Object.keys(resData.banner).map(function (key, idx) {
          return resData.banner[key];
        });
      } else {}
    }).catch(function (err) {
      console.log(err);
    }); // 更新信件未讀數量

    basePost("/api_notice/notice_unread_num").then(function (res) {
      var data = res.data,
          status = res.status;
      var resData = data["res_data"];

      if (status === 200 && data["res_code"] === 1) {
        mailUnreadNum.value = resData.notice_unread_num || 0;
      } else {}
    }).catch(function (err) {
      console.log(err);
    }); // 更聊天室未讀數量

    var getLatestChatUnread = function getLatestChatUnread() {
      basePost("/api_member/unread_num").then(function (res) {
        var data = res.data,
            status = res.status;
        var resData = data["res_data"];

        if (status === 200 && data["res_code"] === 1) {
          chatUnreadNum.value = data.res_content || 0; // 賦予ICON BADGE

          _set_badge_num(chatUnreadNum.value);
        } else {}
      }).catch(function (err) {
        console.log(err);
      });
    };

    $$(document).on('page:beforein', function (e, page) {
      if (page.name == 'home') {
        getLatestChatUnread();
      }
    });
    return {
      authInfo: authInfo,
      mukiConst: mukiConst,
      parseInt: parseInt,
      pageData: pageData,
      btnActiveName: btnActiveName,
      mailUnreadNum: mailUnreadNum,
      openSettingPopup: openSettingPopup,
      chatUnreadNum: chatUnreadNum,
      openWindow: openWindow,
      t: t
    };
  }
};