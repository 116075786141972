export default {
    dialog: {
        msgShow: "訊息提示",
        buttonOk: '確定',
        buttonCancel: '取消',
    },
    photoBrowser:{
        bannerPhotoBrowser:'關閉'
    },
    newFabrics: "新品",
    news: '最新消息',
    chatRoom: '留言板',
    archive: '封存',
    labdipStatus: 'Labdip狀態',
    fabricname: '品名',
    'fabric#': '品號',
    color: '色號',
    receivedDate: '送樣日期',
    leadTime: '預計完成日',
    confirmDate: '確認日期',
    programSchedule: '處理時程表',
    rdStatus: '研發狀態',
    localeSwitch: '語系切換',
    changePwd: '變更密碼',
    logout: '登出',
    signUp: '註冊',
    notification: '通知',
    orderStatus: "訂單狀態",

    logoutSuccess: "已成功登出",
    addToCartSuccess: "加入成功",
    removeFromCartSuccess: "刪除成功",

    youSureToDeleteAllMail: "確定要刪除所有郵件?",
    youSureToDeleteSelectedMail: "確定要刪除所選郵件?",
    youSureToMarkeReadAllMail: "確定要已讀所有郵件?",

    keyword: '關鍵字',

    alertMsg:{
        error: '發生錯誤',
        serverError: '伺服器錯誤，請聯繫維護人員協助處理',
        connectionError: '網路連線不穩定，請稍候再試'
    }
}