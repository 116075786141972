export default {
  props: {
    h: {
      type: [Number, String],
      default: 0
    },
    rwdVal: String
  },
  setup: function setup() {
    function rwdVal(minScreen, minValue, maxScreen, maxValue) {
      var a = (maxValue - minValue) / (maxScreen - minScreen);
      var b = minValue - a * minScreen;
      var sign = '+';

      if (b < 0) {
        sign = '-';
        b = Math.abs(b);
      }

      return "calc(".concat(a, " * var(--view-width) ").concat(sign, " ").concat(b, ")");
    }

    return {};
  }
};