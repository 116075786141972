import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
import _imports_0 from '@/assets/images/icon/eye-to-hide.svg';
import _imports_1 from '@/assets/images/icon/eye-to-view.svg';
var _hoisted_1 = {
  class: ['item-content', 'item-input']
};
var _hoisted_2 = {
  class: "item-inner"
};
var _hoisted_3 = {
  key: 0,
  class: "item-title item-label"
};
var _hoisted_4 = {
  class: "label-txt"
};
var _hoisted_5 = {
  key: 0,
  class: "star-before"
};
var _hoisted_6 = {
  key: 1,
  class: "star-after"
};
var _hoisted_7 = {
  class: "item-input-wrap"
};
var _hoisted_8 = {
  key: 0,
  class: "icon-holder"
};
var _hoisted_9 = {
  class: "field-wrap"
};
var _hoisted_10 = {
  key: 0,
  class: "float-placeholder"
};
var _hoisted_11 = {
  key: 0,
  class: "star-before"
};
var _hoisted_12 = {
  key: 1,
  class: "star-after"
};
var _hoisted_13 = {
  key: 0,
  src: _imports_0,
  alt: "",
  srcset: ""
};
var _hoisted_14 = {
  key: 1,
  src: _imports_1,
  alt: "",
  srcset: ""
};
var _hoisted_15 = {
  key: 0,
  class: "custom-controls-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_f7_icon = _resolveComponent("f7-icon");

  return _openBlock(), _createBlock("div", {
    class: ["base-input", $setup.computeStyStr ? $setup.computeStyStr : null],
    ref: "refEl"
  }, [_renderSlot(_ctx.$slots, "root-start"), _createVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "content-start"), _createVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "inner-start"), $props.labelName ? (_openBlock(), _createBlock("div", _hoisted_3, [_renderSlot(_ctx.$slots, "label-start"), _createVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString($props.labelName) + " ", 1), !$setup.isFloatPlaceholder ? (_openBlock(), _createBlock(_Fragment, {
    key: 0
  }, [$props.star == 'star-before' ? (_openBlock(), _createBlock("span", _hoisted_5, "*")) : _createCommentVNode("", true), $props.star == 'star-after' ? (_openBlock(), _createBlock("span", _hoisted_6, "*")) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)]), _renderSlot(_ctx.$slots, "label-end")])) : _createCommentVNode("", true), !$setup.isCustom ? (_openBlock(), _createBlock(_Fragment, {
    key: 1
  }, [_createVNode("div", {
    class: ["item-input-wrap-outer", $props.itemInputWrapOuterClass]
  }, [_renderSlot(_ctx.$slots, "wrap-start"), _createVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "input-start"), $props.icon || $props.f7 ? (_openBlock(), _createBlock("div", _hoisted_8, [$props.f7 ? (_openBlock(), _createBlock(_component_f7_icon, {
    key: 0,
    f7: $props.f7,
    material: $props.material,
    ios: $props.ios,
    md: $props.md,
    aurora: $props.aurora
  }, null, 8, ["f7", "material", "ios", "md", "aurora"])) : _createCommentVNode("", true), $props.icon ? (_openBlock(), _createBlock("i", {
    key: 1,
    class: ["icon", $props.icon]
  }, null, 2)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createVNode("div", _hoisted_9, [$setup.isFloatPlaceholder ? (_openBlock(), _createBlock("div", _hoisted_10, [_createTextVNode(_toDisplayString($props.placeholder) + " ", 1), $props.star == 'star-before' ? (_openBlock(), _createBlock("span", _hoisted_11, "*")) : _createCommentVNode("", true), $props.star == 'star-after' ? (_openBlock(), _createBlock("span", _hoisted_12, "*")) : _createCommentVNode("", true)])) : _createCommentVNode("", true), (_openBlock(), _createBlock(_resolveDynamicComponent($props.inputTag), {
    ref: "refInput",
    readonly: $props.readonly,
    value: $props.modelValue,
    onInput: _cache[1] || (_cache[1] = function ($event) {
      return [_ctx.$emit('update:modelValue', $event.target.value)];
    }),
    name: $props.name,
    type: $setup.thisType,
    inputmode: $props.inputmode,
    placeholder: $setup.isFloatPlaceholder ? '' : $props.placeholder,
    id: $props.inputId,
    class: $props.inputClass,
    rows: $props.rows,
    cols: $props.cols,
    autocomplete: "off"
  }, null, 8, ["readonly", "value", "name", "type", "inputmode", "placeholder", "id", "class", "rows", "cols"]))]), _createVNode("div", {
    class: "password-tools",
    onClick: _cache[2] || (_cache[2] = function () {
      return $setup.togglePwd && $setup.togglePwd.apply($setup, arguments);
    })
  }, [$setup.isPassword && $setup.thisType !== 'password' ? (_openBlock(), _createBlock("img", _hoisted_13)) : _createCommentVNode("", true), $setup.isPassword && $setup.thisType == 'password' ? (_openBlock(), _createBlock("img", _hoisted_14)) : _createCommentVNode("", true)]), _renderSlot(_ctx.$slots, "input-end")]), $setup.isSelect ? (_openBlock(), _createBlock(_component_f7_icon, {
    key: 0,
    class: "select-chevron-down",
    f7: "chevron_down"
  })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "wrap-end")], 2), $setup.isCustomControls ? (_openBlock(), _createBlock("div", _hoisted_15, [_renderSlot(_ctx.$slots, "custom-controls")])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default"), _renderSlot(_ctx.$slots, "inner-end")]), _renderSlot(_ctx.$slots, "content-end")]), ($props.errMsg || $setup.thisErrMsg) && $props.errMsg !== false ? (_openBlock(), _createBlock("span", {
    key: 0,
    class: "error-msg",
    name: $props.name
  }, _toDisplayString($props.errMsg ? $props.errMsg : $setup.thisErrMsg), 9, ["name"])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "root-end")], 2);
}