import { useI18n } from "vue-i18n";
export default {
  setup: function setup() {
    // 多國語系
    var _useI18n = useI18n({
      messages: {
        "zh-tw": {
          activity: '活動',
          album: '相簿',
          home: '首頁',
          product: '產品',
          member: '會員'
        },
        en: {
          activity: 'Activity',
          album: 'Album',
          home: 'Home',
          product: 'Product',
          member: 'Member'
        }
      }
    }),
        t = _useI18n.t;

    return {
      t: t
    };
  }
};