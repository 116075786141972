import store from '../store/store.js'
import Home from "@/pages/Home";
import Login from '@/pages/Login.vue';
import NotFoundPage from "@/pages/404.vue";


var routes = [
    {
        path: "/",
        async: function ({ app, to, resolve }) {

            store.dispatch('auth/init');
            
            const isLogined = store.state.auth.isLogined;
            // Requested route
            if ( isLogined ) {
                resolve({
                    component: Home
                })
            } else {
                resolve({
                    component: Login
                })
            }
        }
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/forgot_pwd",
        asyncComponent: () => import("@/pages/ForgotPwd"),
    },
    {
        path: "/change_pwd",
        asyncComponent: () => import("@/pages/ChangePwd"),
    },
    {
        path: "/register",
        asyncComponent: () => import("@/pages/Register"),
    },
    {
        path: "/register_complete",
        asyncComponent: () => import("@/pages/RegisterComplete"),
    },
    {
        path: "/notification_list",
        asyncComponent: () => import("@/pages/NotificationList"),
    },
    {
        path: "/notification_detailed/:notice_id",
        asyncComponent: () => import("@/pages/NotificationDetailed"),
    },
    {
        path: "/new_fabrics",
        asyncComponent: () => import("@/pages/NewFabrics"),
    },
    {
        path: "/new_fabric_detailed/:cloth_id",
        asyncComponent: () => import("@/pages/NewFabricDetailed"),
    },
    {
        path: "/news_list",
        asyncComponent: () => import("@/pages/NewsList"),
    },
    {
        path: "/news_detailed/:newsId?",
        asyncComponent: () => import("@/pages/NewsDetailed"),
    },
    {
        path: "/chat_room_list",
        asyncComponent: () => import("@/pages/ChatRoomList"),
    },
    {
        path: "/chat_room_add/:productIdArr?",
        asyncComponent: () => import("@/pages/ChatRoomAdd"),
    },
    {
        path: "/chat_room/:roomId?",
        asyncComponent: () => import("@/pages/ChatRoom"),
    },
    {
        path: "/lab_list",
        asyncComponent: () => import("@/pages/LabList"),
    },
    {
        path: "/rd_status_list",
        asyncComponent: () => import("@/pages/RDStatusList"),
    },
    {
        path: "/order_status_list",
        asyncComponent: () => import("@/pages/OrderStatusList"),
    },



    {
        path: "/mail_list",
        asyncComponent: () => import("@/pages/mail/MailList"),
    },
    {
        path: "/mail_detailed/:mailId",
        asyncComponent: () => import("@/pages/mail/MailDetailed"),
    },
    {
        path: "(.*)",
        component: NotFoundPage,
    },
];

export default routes;
