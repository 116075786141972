import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useField } from "vee-validate";
import { computed, ref } from "vue";
export default {
  components: {},
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      default: ""
    },
    value: {
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    size: {
      default: 20
    },
    name: {
      type: String,
      default: ""
    },
    rules: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    },
    inline: Boolean,
    f7: {
      type: String,
      default: ""
    },
    material: {
      type: String,
      default: ""
    },
    ios: {
      type: String,
      default: ""
    },
    md: {
      type: String,
      default: ""
    },
    aurora: {
      type: String,
      default: ""
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup(props) {
    var type = props.type,
        modelValue = props.modelValue,
        value = props.value,
        name = props.name;
    var rootClassName = computed(function () {
      return "base-".concat(type);
    });
    var iconWrapClassName = computed(function () {
      return "icon-".concat(type, "-wrap");
    });
    var textClassName = computed(function () {
      return "".concat(type, "-text");
    });
    var thisModelValue = computed(function () {
      return props.modelValue;
    });
    var refInput = ref(null);

    var changeHandler = function changeHandler() {};

    var isChecked = computed(function () {
      if (props.type == 'radio') {
        return thisModelValue.value == parseInt(value);
      } else {
        return thisModelValue.value.indexOf(value) > -1;
      }
    }); // 決定要返回給 update:modelValue的值

    var computModelValForUpdate = function computModelValForUpdate(e) {
      var isChecked = e.target.checked;
      var thisVal = e.target.value;

      if (props.type == 'radio') {
        // radio
        if (isChecked) {
          return '';
        } else {
          thisVal;
        }
      } else {
        // checkebox
        if (isChecked) {
          if (thisModelValue.value.indexOf(thisVal) > -1) {
            return thisModelValue.value;
          } else {
            return [].concat(_toConsumableArray(thisModelValue.value), [thisVal]);
          }
        } else {
          var newArr = _toConsumableArray(thisModelValue.value);

          var idx = newArr.indexOf(thisVal);

          if (idx > -1) {
            newArr.splice(idx, 1);
            return newArr;
          } else {
            return newArr;
          }
        }
      }
    }; // validate


    var _useField = useField(name),
        thisErrMsg = _useField.errorMessage,
        thisName = _useField.name,
        thisValue = _useField.value,
        meta = _useField.meta,
        errors = _useField.errors,
        resetField = _useField.resetField,
        validate = _useField.validate,
        handleChange = _useField.handleChange,
        handleBlur = _useField.handleBlur,
        setValidationState = _useField.setValidationState,
        thisChecked = _useField.checked;

    return {
      refInput: refInput,
      rootClassName: rootClassName,
      iconWrapClassName: iconWrapClassName,
      textClassName: textClassName,
      changeHandler: changeHandler,
      isChecked: isChecked,
      thisErrMsg: thisErrMsg,
      computModelValForUpdate: computModelValForUpdate
    };
  }
};