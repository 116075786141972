
import axios from "axios";
import store from '../../store/store';
import { i18n } from "@/js/i18n.js";

// 驗證
axios.defaults.headers.common['userAuthToken'] = localStorage.getItem("token");
// 會員種類
axios.defaults.headers.common['memberType'] = localStorage.getItem("memberType");
// X-Csrftoken
axios.defaults.headers.common['X-Csrftoken'] = getCsrfTokenFromCookie();

const apiBase = axios.create({
    baseURL: "/api/",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/form-data'
    },
    transformRequest: [
        function(data, headers){
            // 多國語系
            headers.common['MukiLang'] =  localStorage.getItem("MukiLang") || 'tw';

            return objToFormData(data)
        }
    ],
    transformResponse: [
        function(res){
            apiBase.defaults.headers.common['X-Csrftoken'] = getCsrfTokenFromCookie();

            try {

                var parsed = JSON.parse(res)
                return parsed;

            }catch(err){

                console.log('err', err)
            }

            return res;
        }
    ],
});

const ErrorMsgDisableHandleArr = [];

apiBase.interceptors.response.use(function (config) {
    const { data } = config;

    // 處理沐奇自定義的錯誤代碼
    if ( data ) {
        const { res_code } = data;
        
        if ( ErrorMsgDisableHandleArr.indexOf(config.url) === -1 ) { 
            // token過期
            switch (res_code) {
                // 登入 token
                case mukiConst.token.INVALID:

                    if ( window.mukiTokenInvalid ) return;

                    window.mukiTokenInvalid = true;

                    store.dispatch('auth/logout');
                    f7.dialog.alert(data["res_content"], "", () => {
                        directURL('/');
                    });

                    break;
                // csr token
                case mukiConst.csrToken.INVALID:

                    if ( window.mukiTokenInvalid ) return;

                    window.mukiTokenInvalid = true;

                    store.dispatch('auth/logout');
                    f7.dialog.alert(data["res_content"], "", () => {
                        directURL('/');
                    });

                    break;
                default:
                    break;
            }

        }

        if ( window.mukiTokenInvalid ) {
            return Promise.reject('錯誤');
        }
    }

    return config;
    
}, function (error) {

    // Do something with request error
    if ( ErrorMsgDisableHandleArr.indexOf(error.config.url) === -1 ) {
        if (error.response) {
            // server responded status code falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
                    if ( error.response.data && error.response.data.Message ) {
                        f7.dialog.alert(error.response.data.Message, "", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: ${i18n.global.t('alertMsg.error')}`, "", () => {
                        });
                    }
    
                    break;
                case 404:
                    if ( error.response.data && error.response.data.Message ) {
                        f7.dialog.alert(error.response.data.Message, "", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: ${i18n.global.t('alertMsg.error')}`, "", () => {
                        });
                    }
                    break
                case 500:
                    if ( error.response.data && error.response.data.Message ) {
                        f7.dialog.alert(error.response.data.Message, "", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: ${i18n.global.t('alertMsg.error')}`, "", () => {
                        });
                    }
                    break
                default:
                    if ( error.response.data && error.response.data.Message ) {
                        f7.dialog.alert(error.response.data.Message, "", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: ${i18n.global.t('alertMsg.error')}`, "", () => {
                        });
                    }
                break
            }
        } else {
            // shutdonw api server
            f7.dialog.alert(i18n.global.t('alertMsg.connectionError'), "", () => {
            });
        }
    }

    return Promise.reject(error);
});

function getCsrfTokenFromCookie() {
    let regex = /.*csrf_cookie_name=([^;.]*).*$/;
    return document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1];
}

const setApiMode = (apiBase, mode) => {
    if ( mode === 'dev' ) {
        apiBase.defaults.baseURL = "/api/";
    }
    return apiBase;
}

function baseGet(url, formInfo, mode) {
    return setApiMode(apiBase, mode).get(url, formInfo);
}

function basePost(url, formInfo, mode) {
    return setApiMode(apiBase, mode).post(url, formInfo);
}

function basePut(url, formInfo, mode) {
    return setApiMode(apiBase, mode).put(url, formInfo);
}

function basePatch(url, formInfo, mode) {
    return setApiMode(apiBase, mode).patch(url, formInfo);
}

function baseDel(url, formInfo, mode) {
    return setApiMode(apiBase, mode).delete(url, formInfo);
}

// 登入
function postLogin(formInfo, mode) {
    var promise = {}
    var url = '/api_common/login';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {

            store.dispatch("auth/login", {
                // 驗證
                token: resData.token,
                // 直接由輸入值取得
                mail: formInfo.email,
                isLogined: true,
                memberType: resData.member_type
            });

            return res;

        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
    
}

// 聊天室細節
function roomDetail(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/detail';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

// 聊天室列表
function roomList(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/list';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

function storeMessageToRoom(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/storeMessage';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

function postImageSave(formInfo, mode) {
    var promise = {}
    var url = '/api_common/imageSave';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

function archiveRoom(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/archiveRoom';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

function newCart(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/getCreate';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

function postNewCart(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/postCreate';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "");
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

// 加入洽詢車
function baseAddInquiry(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/add_cloth_to_cart';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            f7.dialog.alert(data["res_content"], i18n.global.t('addToCartSuccess'), () => {
            });
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "", () => {
            });
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

// 移出洽詢車
function baseRemoveInquiry(formInfo, mode) {
    var promise = {}
    var url = '/api_chat/remove_cloth_to_cart';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            f7.dialog.alert(data["res_content"], i18n.global.t('removeFromCartSuccess'), () => {
            });
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "", () => {
            });
        }
        
        return res;
    })
    .catch((err) => {
        console.log('err', err)
        f7.dialog.alert(
            i18n.global.t('alertMsg.serverError'),
            ""
        );
    });
}

export { 
    baseGet, 
    basePost, 
    basePut, 
    basePatch, 
    baseDel, 
    postLogin, 
    roomDetail, 
    roomList, 
    storeMessageToRoom, 
    postImageSave, 
    archiveRoom , 
    newCart, 
    postNewCart,
    // 洽詢車
    baseAddInquiry,
    baseRemoveInquiry
};
