
// import { createStore } from 'framework7/lite';
import { f7 } from "framework7-vue";
import { baseGet, basePost } from "@/js/services/baseService";
import { createStore, createLogger } from 'vuex'
import { i18n } from "@/js/i18n.js";
import axios from "axios";
import modules from './modules' // 引入modules

const store = createStore({
  modules, // 放到這裡
  state() {
    return {
      // f7 view 準備就緒
      isViewReady: false,
      // 語系
      locale: null,
    }
  },
  getters: {
    
  },
  actions: {
    // 初始
    init({commit}) {
      var locale = localStorage.getItem("MukiLang");
      // 語系
      if (locale) {
        commit('updateLocale', locale)
      } else {
        commit('updateLocale', mukiConst.locale['EN'])
      }
    },
  },
  mutations: {
    // 更新語系
    updateLocale(state, langStr) {

      var locale = null;

      switch (langStr) {
        case mukiConst.locale['TW']:
            locale = mukiConst.locale['TW'];
          break;
        case mukiConst.locale['EN']:
            locale = mukiConst.locale['EN'];
          break;
        default:
          break;
      }

      if ( typeof i18n !== "undefined" ) {
        if ( i18n.global.locale.value ) {
          i18n.global.locale.value = locale;
        }
      }
      
      if ( locale ) {
        state.locale = locale
        localStorage.setItem("MukiLang", locale);
      }

    },
    // view 已準備就緒
    setViewReady(state) {
      state.isViewReady = true
    }
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})
export default store;
