export default {
  props: {
    sty: {
      default: ''
    },
    size: {
      default: ''
    },
    title: {
      default: 'No Data'
    },
    text: {
      default: '目前沒有資料'
    },
    textColor: {
      default: ''
    },
    media: {
      type: Boolean,
      default: true
    }
  }
};